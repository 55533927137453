.download-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.download-inner-container {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    /* flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed; */
    margin-top: 70px;
    /* background: #F4F4F4; */
}

.download-captain-left-side{
    background-color: #FCFCFC;
    width: calc(100% - 600px);
    /* min-width: 40%; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 20px;
    overflow-y: auto;
    }

.download-captain-container{
    width: 70%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-top: 15px; */
    margin-bottom: 10px;
    margin-left: 20%;
}

/* .download-mockup{
    height: 400px;
    margin: 0px 15px;
} */

.download-text-container{
    /* margin-top: 10px; */

    width: 75%;
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; */
}

.download-captain-logo{
    height: 50px;
    width: 50px;
    /* margin-bottom: 5px; */
}

.download-captain-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #262525;
    margin-top: 10px;
}

.download-captain-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #262525;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.google-play-button-container{
    border: none;
    background: none;
    padding: none;
    margin-top: 10px;
    margin-bottom: 10px;
}

.download-google-play{
    border: none !important;
    box-sizing: border-box;
    border-radius: 6px;
    width: 130px;
    height: 40px;
    text-align: center;
    padding: none;
    margin-left: -5px;
}

.download-captain-right-side{
    /* background: linear-gradient(to top left, #5D2BCC 0%, #2F1CCD 41.98%, #000CCF 100%); */
    background-color: #0b1321;
    width: 600px;
    height: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.download-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
    color: #000CCF;
    margin-top: 10px;
    padding-bottom: 50px;
}

.next-box{
    width: 101px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
}

.next-button{
    background: #FCFCFC;
    border-radius: 40px;
    border:1px solid;
    border-color: linear-gradient(45deg, #0074E0 -35.8%, #00BED4 100%);

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    color: #0074E0;
    width: 139px;
    height: 40px;
}

/* ------mobile------ */
/* ------------------ */

.mobile-download-inner-container {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    /* flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; */
    position: fixed;
    top: 70px;
    /* background: #F4F4F4; */
}

.mobile-download-captain-left-side{
    background-color: #FCFCFC;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    padding-top: 20px;
    }

.mobile-download-captain-container{
    width: 88%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    /* margin-bottom: 10px; */
    margin-left: 20px;
}

.mobile-download-text-container{
    margin-top: 5px;
    /* height: 80%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.mobile-download-captain-logo{
    height: 30px;
    width: 30px;
    /* margin-bottom: 5px; */
}

.mobile-download-captain-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #262525;
    margin-top: 10px;
}

.mobile-download-captain-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #262525;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-google-play-button-container{
    border: none;
    background: none;
    padding: none;
    margin-top: 5px;
    margin-bottom: 5px;
}

.mobile-download-google-play{
    border: none !important;
    box-sizing: border-box;
    border-radius: 6px;
    width: 130px;
    height: 40px;
    text-align: center;
    padding: none;
    margin-left: -5px;
    margin-right: 5px;
}

.mobile-download-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
    color: #000CCF;
    margin-top: 20px;
    /* margin-bottom: 10px; */
}

.mobile-next-box{
    width: 101px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
}

.mobile-next-button{
    background: #FCFCFC;
    border-radius: 40px;
    border:1px solid;
    border-color: linear-gradient(45deg, #0074E0 -35.8%, #00BED4 100%);

    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    color: #0074E0;
    width: 139px;
    height: 40px;
}