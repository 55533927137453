.confirm-modal-container{
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  position:absolute;
  right:0px;
  top:0px;
  background: rgba(0,0,0,.5);
}

.confirm-modal-text-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.confirm-modal-inner-container{
  width:464px;
  height:427px;
  z-index:99;
  background: #FCFCFC;
  border-radius: 3px;
  box-shadow: 2px 8px 48px -16px rgb(0 0 0 / 40%);
}

.close-modal-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.close-modal {
  width: 25px;
  height: 25px;
  margin: 10px;
}

.phone-icon{
  height: 80px;
  width: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.verify-text{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #262525;
  margin: 0px 10px;
  margin-bottom: 10px;
}

.verify-phone-subtext{
  font-family: Questrial;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #262525;
  margin: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.confirm-phone-input{
  color: #0B1246;
  border: none;
  height: 36px;
  width: 195px;
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.confirm-phone-input::placeholder{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #919397;
}

.verify-button{
  padding: 8px 93px;
  margin: 10px;
  background: #AAABAF; 
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  height: 40px;
  width: 400px;
  text-align: center;
  color: #FFFFFF;
}
.confirm-code-input-line{
  margin-bottom: 10px;
}

.resend-code-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

.resend-code-icon{
  margin-left: 10px;
}

.resend-code-text{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;
  color: #919397;
}

/* ------mobile------ */
/* ------------------ */

.mobile-confirm-modal-container{
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  position:absolute;
  right:0px;
  top:0px;
  background: rgba(0,0,0,.5);
}

.mobile-confirm-modal-text-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.mobile-confirm-modal-inner-container{
  width:342px;
  height:343.5px;
  z-index:99;
  background: #FCFCFC;
  border-radius: 3px;
  box-shadow: 2px 8px 48px -16px rgb(0 0 0 / 40%);
}

.mobile-close-modal-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.mobile-close-modal {
  width: 25px;
  height: 25px;
  margin: 10px;
}

.mobile-phone-icon{
  height: 40px;
  width: 40px;
  /* margin-top: 3px; */
  margin-bottom: 3px;
}

.mobile-verify-text{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #262525;
  margin: 0px 5px;
  margin-bottom: 10px;
}

.mobile-verify-phone-subtext{
  font-family: Questrial;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  color: #262525;
  margin: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mobile-confirm-phone-input{
  color: #0B1246;
  border: none;
  height: 36px;
  width: 195px;
  margin-top: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}

.mobile-confirm-phone-input::placeholder{
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #919397;
}

.mobile-verify-button{
  padding: 8px 50px;
  margin: 10px;
  background: #AAABAF; 
  border-radius: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  border: none;
  height: 40px;
  width: 300px;
  text-align: center;
  color: #FFFFFF;
}
.mobile-confirm-code-input-line{
  margin-bottom: 10px;
}

.mobile-resend-code-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
  width: 35%;
}

.mobile-resend-code-icon{
  margin-left: 10px;
  height: 14px;
}

.mobile-resend-code-text{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  text-decoration-line: underline;
  color: #919397;
}