@media only screen and (min-width: 451px){
    .google-location-input{
        width: 70%;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        box-sizing: border-box;
        border-radius: 3px;
        height: 35px;

        padding-left: 8px;
        font-family: Questrial;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #262525;
        box-sizing: border-box;
    }

    .google-location-input::placeholder{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #DFDFDF;
        padding-left: 5px;
    }
}

@media only screen and (max-width: 450px) {
    .google-location-input{
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        box-sizing: border-box;
        border-radius: 3px;
        height: 35px;
    
        padding-left: 8px;
        font-family: Questrial;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #262525;
        box-sizing: border-box;
    }
    
    .google-location-input::placeholder{
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #DFDFDF;
        padding-left: 5px;
    }
}

