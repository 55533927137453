.container{
  width:100%;
  height:100%;
  overflow: hidden;
}

.inner-container{
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 70px;
}

.left-side{
  height:100%;
  background-color: #FCFCFC;
  width: calc(100% - 560px);
  min-width: 40%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.start-gfo-text{
  /* height: 100px; */
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 110%;
  color: #262525;
}

.start-gfo-subtext{
  font-weight: 400;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #262525;
  display: flex;
  padding-top: 5px;
}

.create-account-container{
  width: 70%;
  height: 80%;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 50px;
  padding-top: 20px;
  margin-left: 30px;
  margin-bottom: 20px;
  /* padding-bottom: 20px;  */
}

.create-account{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #262525;
  padding-top: 15px;
  padding-bottom: 3px;
  width: 70%;
}

.create-account-subtext{
  font-family: Questrial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #262525;
  padding-bottom: 15px;
  width: 70%;
}

.gfo-logo {
  height: 50px;
  width: 275px;
}

.right-side{
  background-color: #0B1321;
  width: 708px;
  max-width: 60%;
  height: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.start-text-input{
  margin-bottom: 20px;
  width: 70%;
}

.continue-box {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.continue-button {
  background: #AAABAF;
  border-radius: 40px;
  border: none;
  border-style: hidden;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  color: #FCFCFC;
  width: 139px;
  height: 40px;

}

.start-page-contact-us{
  font-family: Questrial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: #000CCF;
  width:70%;
  height: 25px;
}

.start-bottom-elements-container{
  display: flex;
  width: 70%;
  padding-bottom: 30px;
  flex-direction: row;
  align-items: center;
}


/* --------mobile--------- */
/* ----------------------- */


.mobile-inner-container{
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 70px;
}

.mobile-left-side{
  height:100%;
  background-color: #FCFCFC;
  width: 100%;
  min-width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
}

.mobile-start-gfo-text{
  /* height: 100px; */
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 110%;
  color: #262525;
}

.mobile-start-gfo-subtext{
  font-weight: 400;
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  color: #0B1246;
  display: flex;
}

.mobile-create-account-container{
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 40px 30px;
  /* padding-bottom: 20px;  */;
}

.mobile-create-account{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #262525;
  padding-top: 10px;
  width: 70%;
}

.mobile-create-account-subtext{
  font-family: Questrial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #262525;
  padding-bottom: 15px;
  width: 100%;
}

.mobile-gfo-logo {
  height: 50px;
  width: 275px;
}

.mobile-start-text-input{
  margin-bottom: 15px;
  width: 100%;
}

.mobile-input-title{
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #262525;
}

.mobile-continue-box {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.mobile-continue-button {
  background: #AAABAF;
  border-radius: 40px;
  border: none;
  border-style: hidden;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #FCFCFC;
  width: 112px;
  height: 32.6px;

}

.mobile-start-page-contact-us{
  font-family: Questrial;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: #000CCF;
  width:70%;
  height: 20px;
}

.mobile-start-bottom-elements-container{
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  flex-direction: row;
  align-items: center;
}
