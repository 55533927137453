.pos-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .pos-inner-container {
      width: 100%;
      height: calc(100% - 70px);
      display: flex;
      /* flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      position: fixed; */
      margin-top: 70px;
  }
  
  .pos-left-side {
    background-color: #FCFCFC;
    width: calc(100% - 600px);
    /* min-width: 40%; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 20px;
    overflow-y: auto;
  }
  
  .pos-system-container{
      width: 55%;
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      /* margin: 20px; */
      margin-top: 15px;
      margin-bottom: 10px;
      margin-left: 20%;
  }
  
  .pos-text{
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 36px;
      color: #262525;
  }
  
  .pos-subtext{
      font-family: Questrial;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #262525;
      margin-top: 10px;
      margin-bottom: 10px;
  }
  
  .pos-bullet-container{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 10px;
      width: 100%;
      box-sizing:border-box;
      /* margin-bottom: 10px;; */
  }

  .pos-button{
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 6px;
    width:50%;
    height: 60px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    box-sizing:border-box;
  }

  .pos-button-other{
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 6px;
    width:100%;
    height: 60px;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    box-sizing:border-box;
  }

  .pos-button-none{
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 6px;
    width:100%;
    height: 60px;
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    box-sizing:border-box;
  }

  .pos-circle{
      border-radius: 200px;
      width: 24px;
      height: 24px;
      border: 1px solid #8C8C8C;
      box-sizing: border-box;
      margin-left: 8px;
      margin-right: 7px; 
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }

  .pos-circle-selected{
    border-radius: 200px;
    width: 16px;
    height: 16px;
    border: none;
    background-color: #0086B7;
    box-sizing: border-box;
  }

  .pos-button:hover{
    border: 3px solid #0086B7;
    box-sizing: border-box;
  }

  .pos-button-other:hover{
    border: 3px solid #0086B7;
    box-sizing: border-box;
}

.pos-button-none:hover{
    border: 3px solid #0086B7;
    box-sizing: border-box;
}

  select{
    margin-left: 15px;
    border: 1px solid #8C8C8C;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    width: 75%;
    margin-right: 8px;
  }
  .pos-bullet-container-text{
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #262525;
      margin:0px 11px;
      border-radius: 3px;
  }
  
  .pos-right-side{
    /* background: linear-gradient(to top left, #5D2BCC 0%, #2F1CCD 41.98%, #000CCF 100%); */
    background-color: #0B1321;
    width: 600px;
    height: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  /* .gmb-right-side-image{
      
  } */
  
  .pos-contact-us{
      font-family: Questrial;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      text-align: center;
      color: #000CCF;
      margin-top: 10px;
      padding-bottom: 10px;
  }

  .pos-bottom-elements-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }

  .pos-continue-button{
    background: #AAABAF;
    border-radius: 40px;
    border: none;
    border-style: hidden;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    color: #FCFCFC;
    width: 139px;
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  /* ------mobile------ */
  /* ------------------ */
  
  
  .mobile-pos-inner-container {
      width: 100%;
      height: calc(100% - 70px);
      display: flex;
      /* flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;*/
      position: fixed; 
      top: 70px;
  }
  
  .mobile-pos-left-side {
    /* height: 100%; */
    background-color: #FCFCFC;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    padding-top: 20px;
  }
  
  .mobile-pos-system-container{
    width: 88%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin: 20px; */
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.mobile-pos-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #262525;
}

.mobile-pos-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #262525;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-pos-bullet-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    width: 100%;
    box-sizing:border-box;
    /* margin-bottom: 10px;; */
}

.mobile-pos-button{
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 6px;
  width:50%;
  height: 48px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  box-sizing:border-box;
}

.mobile-pos-button-other{
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 6px;
  width:100%;
  height: 48px;
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
  box-sizing:border-box;
}

.mobile-pos-button-none{
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 6px;
  width:100%;
  height: 48px;
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
  box-sizing:border-box;
}

.mobile-pos-circle{
    border-radius: 200px;
    width: 24px;
    height: 24px;
    border: 1px solid #8C8C8C;
    box-sizing: border-box;
    margin-left: 8px;
    margin-right: 7px; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mobile-pos-circle-selected{
  border-radius: 200px;
  width: 16px;
  height: 16px;
  border: none;
  background-color: #0086B7;
  box-sizing: border-box;
}

/* .mobile-pos-button:hover{
  border: 3px solid #0086B7;
  box-sizing: border-box;
}

.mobile-pos-button-other:hover{
  border: 3px solid #0086B7;
  box-sizing: border-box;
}

.mobile-pos-button-none:hover{
  border: 3px solid #0086B7;
  box-sizing: border-box;
} */

.mobile-select{
  margin-left: 15px;
  border: 1px solid #8C8C8C;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  width: 75%;
  margin-right: 8px;
}
.mobile-pos-bullet-container-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #262525;
    margin:0px 11px;
    border-radius: 3px;
}

.mobile-pos-right-side{
  /* background: linear-gradient(to top left, #5D2BCC 0%, #2F1CCD 41.98%, #000CCF 100%); */
  background-color: #0B1321;
  width: 600px;
  height: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mobile-pos-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
    color: #000CCF;
    margin-top: 10px;
    padding-bottom: 10px;
}

.mobile-pos-bottom-elements-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
}

.mobile-pos-continue-button{
  background: #AAABAF;
  border-radius: 40px;
  border: none;
  border-style: hidden;

  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: center;
  color: #FCFCFC;
  width: 139px;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}