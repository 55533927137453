.gmb-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gmb-inner-container {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    /* flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed; */
    margin-top: 70px;
}

.gmb-left-side {
  background-color: #FCFCFC;
  width: calc(100% - 600px);
  /* min-width: 40%; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 20px;
  overflow-y: auto;
}

.connect-restaurant-container{
    width: 55%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin: 20px; */
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 20%;
}

.connect-restaurant-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #262525;
}

.connect-restaurant-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #262525;
    margin-top: 10px;
    margin-bottom: 10px;
}

.gmb-bullet-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    /* margin-bottom: 10px;; */
}

.gmb-bullet-container-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #262525;
    margin:0px 11px;
}

.gmb-signin{
    margin-top: 15px;
}

.gmb-right-side{
  /* background: linear-gradient(to top left, #5D2BCC 0%, #2F1CCD 41.98%, #000CCF 100%); */
  background-color: #0B1321;
  width: 600px;
  height: 100%;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* .gmb-right-side-image{
    
} */

.gmb-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
    color: #000CCF;
    margin-top: 10px;
    padding-bottom: 50px;
}

.gmb-continue-button{
    color:white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: linear-gradient(41.88deg, #0086B7 0%, #00BED4 100%);
    border-radius: 40px;
    width:221px;
    height: 40px;
    border: none;
    margin: 20px 0px;
}

/* ------mobile------ */
/* ------------------ */


.mobile-gmb-inner-container {
    width: 100%;
    height: calc(100% - 70px);
    /* display: flex; */
    /* flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;*/
    position: fixed; 
    overflow-y: auto;
    top: 70px;
}

.mobile-gmb-left-side {
  /* height: 100%; */
  background-color: #FCFCFC;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  overflow-y: auto;
  padding-top: 20px;
}

.mobile-connect-restaurant-container{
    width: 80%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin: 20px; */
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.mobile-connect-restaurant-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #262525;
}

.mobile-connect-restaurant-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #262525;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-gmb-bullet-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 5px;
    /* margin-bottom: 10px;; */
}

.mobile-gmb-bullet-container-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #262525;
    margin:0px 8px;
}

.mobile-gmb-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
    color: #000CCF;
    /* margin-top: 10px; */
    padding-bottom: 10px;
}