.complete-activation-outer-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.complete-activation-inner-container {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    /* flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed; */
    margin-top: 70px;
    /* background: #F4F4F4; */
}

.complete-activation-left-side{
    /* height: 100%; */
    background-color: #FCFCFC;
    width: calc(100% - 600px);
    /* min-width: 40%; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 20px;
    overflow-y: auto;
    }

.complete-activation-container{
    width: 60%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
    /* padding-top: 20px; */
    margin-bottom: 10px;
    margin-left: 20%;
}

.complete-activation-mockup{
    width: 465px;
}

.complete-activation-text-container{
    height: 390px;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.complete-activation-logo{
    height: 50px;
    width: 50px;
    /* margin-bottom: 5px; */
}

.complete-activation-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 44px;
    line-height: 46px;
    color: #262525;
    margin-top: 10px;
    margin-bottom: 20px;
}

.complete-activation-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #262525;
    margin-top: 10px;
    margin-bottom: 10px;
}

.complete-activation-right-side{
    /* background: linear-gradient(to top left, #5D2BCC 0%, #2F1CCD 41.98%, #000CCF 100%); */
    background-color: #0b1321;
    width: 600px;
    height: 100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.complete-activation-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
    color: #000CCF;
    margin-top: 10px;
    padding-bottom: 50px;
}


.chat-button{
    width: 176px;
    height: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FCFCFC;
    border: none;
    margin-top: 15px;
    background: linear-gradient(45deg, #0074E0 -35.8%, #00BED4 100%);
    border-radius: 40px;

}

/* ------mobile------ */
/* ------------------ */

.mobile-complete-activation-inner-container {
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    /* flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; */
    position: fixed;
    top: 70px;
    /* background: #F4F4F4; */
}

.mobile-complete-activation-left-side{
    height: 100%;
    background-color: #FCFCFC;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    padding-top: 20px;
    }

.mobile-complete-activation-container{
    width: 88%;
    /* height: 80%; */
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
}


.mobile-complete-activation-text-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
}


.mobile-complete-activation-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #262525;
    margin-top: 20px;
    margin-bottom: 10px;
}

.mobile-complete-activation-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #262525;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mobile-complete-activation-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
    color: #000CCF;
    margin-top: 10px;
    padding-bottom: 50px;
}

.mobile-chat-button{
    width: 166px;
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FCFCFC;
    border: none;
    margin-top: 15px;
    background: linear-gradient(45deg, #0074E0 -35.8%, #00BED4 100%);
    border-radius: 40px;

}
