.footer{
    width:100%;
    height:167px;
    background-color:#060A21;;
    position:fixed;
    bottom:0px;

    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;

    color:white;
}


.flag{
    padding-left: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flag-img {
    height: 64px;
    width: 64px;
}

.copyright-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    height: 24px;
    color: #FFFFFF;
    margin-left: 15px;;
}


.links{
    display:flex;
    flex-direction:row;
    padding-right: 40px;
}

.links a{
    padding-left:10px;
    padding-right:10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: none;
}