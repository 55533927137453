.restaurant-container{
    width:100%;
    height:100%;
    overflow: hidden;
}

  
.restaurant-inner-container{
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    margin-top: 70px;
}

.restaurant-left-side{
    background-color: #FCFCFC;
    width: calc(100% - 600px);
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-top:20px;
    overflow-y:auto;
}

.restaurant-details-container{
    width: 70%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    margin-left: 20%;
}

.restaurant-details{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    color: #262525;
    padding-top: 5px;
    padding-bottom: 3px;
    width: 80%;
}

.restaurant-details-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #262525;
    padding-bottom: 30px;
    width: 80%;
}


.right-side-map{
    /* background: linear-gradient(to top left, #5D2BCC 0%, #2F1CCD 41.98%, #000CCF 100%); */
    background-color: #0B1321;
    width: 600px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.restaurant-text-input{
    margin-bottom: 30px;
    margin-top: 5px;
    width: 80%;
}

.restaurant-input-title{
    width: 80%;
    margin-bottom: 5px;
    font-family: Questrial;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #262525;
}

.display-restaurant-place {
    background: white;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 6px;
    width: 70%;
    height: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #262525;
    /* margin-top: 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map-location-icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 70%;
}

.restaurant-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: #000CCF;
    margin-bottom: 50px;
    margin-left: 20%;
    margin-top: 5px;
}

/* ------mobile------ */
/* ------------------ */
  
.mobile-restaurant-inner-container{
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    margin-top: 70px;
}

.mobile-restaurant-left-side{
    background-color: #FCFCFC;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top:20px;
    overflow-y:auto;
}

.mobile-restaurant-details-container{
    width: 85%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    margin-left: 20px;
}

.mobile-restaurant-details{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #262525;
    padding-top: 5px;
    padding-bottom: 3px;
    width: 80%;
}

.mobile-restaurant-details-subtext{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #262525;
    padding-bottom: 30px;
    width: 100%;
}


.mobile-restaurant-text-input{
    margin-bottom: 30px;
    margin-top: 5px;
    width: 100%;
}

.mobile-restaurant-input-title{
    width: 100%;
    margin-bottom: 5px;
    font-family: Questrial;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #262525;
}

.mobile-display-restaurant-place {
    background: white;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 6px;
    width: 70%;
    height: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #262525;
    /* margin-top: 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mobile-map-location-icon{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.mobile-restaurant-contact-us{
    font-family: Questrial;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: #000CCF;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-top: 5px;
}

