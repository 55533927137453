@media only screen and (min-width: 451px){
    .svg-circle-container{
        display:flex;
        align-items:center;
        justify-content:center;
        /* background-color:#0B1246; */
        width:15px;
        height:15px;
        min-width: 15px;
        min-height: 15px;
        border-radius:100px;
        border: 2px solid #8C8C8C;
        box-sizing: border-box;
    }

    .svg-circle-container-selected{
        display:flex;
        flex-direction: row;
        align-items:center;
        justify-content:center;
        background-color:#000CCF;
        width:15px;
        height:15px;
        min-width: 15px;
        min-height: 15px;
        border-radius:100px;
        border: none;
    }

    .svg-circle-container-complete{
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:#0B1246;
        width:15px;
        height:15px;
        min-width: 15px;
        min-height: 15px;
        border-radius:100px;
        border: none;
    }
    
    .svg-circle-container-complete svg{
        fill:white;
        height:8px;
        width:8px;
    }

    .progress-bar-container{
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 15px;
        margin-left: 20%;
    }

    .progress-bar-line{
        border: none;
        height: 4px;
        width: 100%;
        border-radius: 100px;
        margin: 4px 0px;
        background-color: #DFDFDF;
    }

    .progress-bar-line-selected{
        border: none;
        height: 4px;
        width: 100%;
        border-radius: 100px;
        margin: 4px 0px;
        background-color: #000CCF;
    }

    .progress-bar-line-complete{
        border: none;
        height: 4px;
        width: 100%;
        border-radius: 100px;
        margin: 4px 0px;
        background-color: #0B1246;
    }


    .progress-bar-subcontainer{
        width: 20%;
        min-width: 70px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 2px;
    }

    .progress-text-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items:center;
    }

    .progress-text{
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #757575;
        margin: 0px 4px;
    }

    .progress-text-selected{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        color: #0B1246;
        margin: 0px 4px;
    }

    .progress-text-complete{
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #0B1246;
        margin: 0px 4px;
    }

    .progress-selected-dot{
        border: none;
        border-radius: 100px;
        height: 5px;
        width: 5px;
        background-color: white;
    }
}

@media only screen and (max-width: 450px){
    .svg-circle-container{
        display:flex;
        flex-direction: row;
        align-items:center;
        justify-content:center;
        /* background-color:#0B1246; */
        width:12px;
        height:12px;
        min-width: 12px;
        min-height: 12px;
        border-radius:100px;
        border: 2px solid #8C8C8C;
        box-sizing: border-box;
    }

    .svg-circle-container-selected{
        display:flex;
        flex-direction: row;
        align-items:center;
        justify-content:center;
        background-color:#000CCF;
        width:12px;
        height:12px;
        min-width: 12px;
        min-height: 12px;
        border-radius:100px;
        border: none;
    }

    .svg-circle-container-complete{
        display:flex;
        align-items:center;
        justify-content:center;
        background-color:#0B1246;
        width:12px;
        height:12px;
        min-width: 12px;
        min-height: 12px;
        border-radius:100px;
        border: none;
    }
    
    .svg-circle-container-complete svg{
        fill:white;
        height:8px;
        width:8px;
    }

    .progress-bar-container{
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 15px;
        margin-left: 20px;
    }

    .progress-bar-line{
        border: none;
        height: 4px;
        width: 100%;
        border-radius: 100px;
        margin: 4px 0px;
        background-color: #DFDFDF;
    }

    .progress-bar-line-selected{
        border: none;
        height: 4px;
        width: 100%;
        border-radius: 100px;
        margin: 4px 0px;
        background-color: #000CCF;
    }

    .progress-bar-line-complete{
        border: none;
        height: 4px;
        width: 100%;
        border-radius: 100px;
        margin: 4px 0px;
        background-color: #0B1246;
    }


    .progress-bar-subcontainer{
        width: 20%;
        min-width: 65px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 2px;
    }

    .progress-text-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items:center;
    }

    .progress-text{
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #757575;
        /* margin: 0px 2px; */
        margin-left: 1px;
    }

    .progress-text-selected{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        color: #0B1246;
        margin-left: 1px;
    }

    .progress-text-complete{
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        color: #0B1246;
        margin-left: 1px;
    }

    .progress-selected-dot{
        border: none;
        border-radius: 100px;
        height: 6px;
        width: 6px;
        background-color: white;
    }
}