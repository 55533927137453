

@media only screen and (max-width: 450px){
    .gfosubheader{
        height: 74px;
        position: fixed;
        width: 100%;
        top: 70px;
        background: #F4F4FB;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    
    .gfo-logo{
        height: auto;
        width: auto;
        max-height: 40px;
        padding-left: 20px;
    }
  }
  
  @media only screen and (min-width: 451px){
    .gfosubheader{
        height: 104px;
        position: absolute;
        width: 100%;
        top: 70px;
        background: #F4F4FB;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    
    .gfo-logo{
        height: auto;
        width: auto;
        max-height: 55px;
        padding-left: 80px;
    }
  }