.header{
    width:100%;
    height:70px;
    background-color:#060A21;;
    position:fixed;
    top:0px;
    
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
}

@media only screen and (max-width: 450px){
  .header{
    width:100%;
    height:70px;
    background-color:#060A21;;
    position:fixed;
    top:0px;
    
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
  }

  .captain-logo{
    padding: 0px;
    margin: 0px;
  }
}

@media only screen and (min-width: 451px){
  .captain-logo{
    padding-left: 80px;
    margin-top:5px;
  }
}



