html, body{
  width:100%;
  height:100%;
  box-sizing:border-box;
  margin: 0px;
  font-family: Poppins;
  font-style: normal;
}

#root{
  width:100%;
  height:100%;
  box-sizing:border-box;
}

@font-face {
  font-family: OPTIChampionBold;
  src: url("../fonts/OPTIChampionBold.otf") format("opentype");
}

@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight:400;
}

@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight:500;
}

@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight:600;
}

@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-Bold.ttf") format("truetype");
  font-weight:700;
}

@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight:900;
}


.text-input{
  background-color: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 3px;
  height:38px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.mobile-text-input{
  background-color: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 3px;
  height:34px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.text-input::placeholder{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #DFDFDF;
  padding: 5px;
}

.mobile-text-input::placeholder{
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #DFDFDF;
  padding: 3px;
}
